import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Welcome to My Blog",
  "date": "2014-06-16T14:33:09-05:00"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This blog is dedicated for technical resources that I’ve worked on or learned over the years. It is not for profit and mainly for learning purpose. Feel free to contact me for any questions!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      